import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hei!</h1>
    <p>
      Her kommer snart mine malerier. Mens du venter, kan du kanskje ta en titt på det
      under ;-)
    </p>
    <div style={{ width: `1080px`, maxWidth: `100%`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <p>
      Ga det mersmak, så finnes det flere på <a href="https://www.instagram.com/jea.nave/">Instagram</a>...
    </p>
    <p>Hilsen<br/>Jeanette</p>
  </Layout>
)

export default IndexPage
